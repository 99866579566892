<template>
  <div>
    <header>
      <nav class="first-nav">
        <img
          src="images/logo/logo-light-blue.png"
          alt="DriftList.com logo"
          id="menu-logo"
        />
      </nav>
      <nav class="middle-nav">
        <router-link class="nav-link" to="/">Home</router-link>
        <router-link class="nav-link" to="/events">My Events</router-link>
        <router-link class="nav-link" to="/team">My Team</router-link>

        <a class="nav-link hidden">Events</a>
        <a class="nav-link hidden">Drivers</a>
        <a class="nav-link hidden">Cars</a>
        <a class="nav-link hidden">Merchandise</a>
        <a class="nav-link" href="#socials">Socials</a>
        <a class="nav-link" href="#socials">Contact</a>
      </nav>
      <nav class="end-nav">
        <button @click="login()" v-if="!user" class="btn btn-success">
          Login or Sign Up
        </button>
        <!--
        <button class="btn btn-link" v-else @click="logout()">
          

          {{user?.providerData[0]?.displayName}}
        </button>
        -->
        <div class="dropdown" v-if="user">
          <a
            class="btn dropdown-toggle m-0 p-1 ps-2 pe-2 text-white"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              class="rounded-circle"
              height="30px"
              :src="user?.providerData[0]?.photoURL"
            />
          </a>
          <ul
            class="dropdown-menu text-dark"
            aria-labelledby="dropdownMenuButton1"
          >
            <li class="text-center" v-if="isAdmin">
              <span class="text-success">ADMIN</span>
            </li>
            <li v-if="isAdmin">
              <hr class="m-0 mt-1 mb-1 p-0" />
            </li>
            <li><a class="dropdown-item" href="#">User Settings</a></li>
            <li>
              <hr class="m-0 mt-1 mb-1 p-0" />
            </li>
            <li><a class="dropdown-item" @click="logout()">Logout</a></li>
          </ul>
        </div>
      </nav>
    </header>

    <!--TODO: Router view here-->

    <router-view></router-view>
    <footer>
      Thanks to
      <a href="https://simplemaps.com/data/us-zips" nofollow>Simple Maps</a> for
      use of their geolocation dataset!
    </footer>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";


import {
  getFirestore,
  collection,
  getDocs,
  
} from "firebase/firestore";

import { firebaseConfig } from "@/firebase_config.js";

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export default {
  name: "App",
  components: {},
  data() {
    return {
      loaded: false,
      results_loaded: false,
      user: null,
      isAdmin: false,
    };
  },
  methods: {
    login: async function () {
      console.debug("Login !");
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      try {
        const result = await signInWithPopup(auth, provider);
        this.user = result.user;
        console.debug(`User: ${this.user}`);
        console.debug(this.user);
      } catch (e) {
        this.showToast(
          `An error occurred when logging in: ${JSON.stringify(e)}`,
          "Error Logging in"
        );
        console.error(e);
      }
      this.checkIsAdmin();
    },
    logout: async function () {
      const auth = getAuth();
      try {
        await signOut(auth);
        this.user = null;
        console.debug("User logged out");
      } catch (e) {
        this.showToast(
          `An error occurred when logging out: ${JSON.stringify(e)}`,
          "Error Logging out"
        );
        console.error(e);
      }
      this.checkIsAdmin();
    },
    checkIsAdmin: async function () {
      //const auth = getAuth();
      getAuth()
      try {
        const eventsCol = collection(db, "admins");
        //const allDocs = await getDocs(eventsCol);
        await getDocs(eventsCol);
        console.debug("Admin");
        this.isAdmin = true;
      } catch (e) {
        console.error(e);
        this.isAdmin = false;
      }
    },
  },
};
</script>

<style>
.router-link-active{
  font-weight: bold;
}
</style>
