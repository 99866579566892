<template>
  <!-- Toast -->
  <div
    :class="'toast ' + (toast.show ? 'show' : '')"
    role="alert"
    aria-live="assertive"
    style="display: none"
    ref="toast"
    aria-atomic="true"
  >
    <div class="toast-header text-light bg-primary">
      <strong class="me-auto">{{ toast.title }}</strong>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
    <div class="toast-body text-light bg-primary">{{ toast.message }}</div>
  </div>

  <main>
    <section id="my_team">
      <div class="container p-3">
        <div class="rounded border bg-light m-2 p-4">
          <h2 class="text-dark">MY TEAM</h2>
          <div class="text">
            Manage your team and coordinate your events! Your team is users that
            can view your events and blocked times. Please note: to see other
            users' calendars and blocked times, they'll need to add you to their
            team as well.
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col"></div>
        </div>
        <div class="row">
          <div
            class="col p-3 col-3 text-center overflow-hidden"
            v-for="(member, idx) in team_members"
            v-bind:key="'member' + idx"
          >
            <div class="rounded bg-light m-2 border p-3">
              <img class="thumbnail rounded" :src="getGravatarUrl(member)" />
              <div class="text mt-2 mb-2">{{ member }}</div>
              <a
                v-if="member == user.email"
                href="http://en.gravatar.com/emails/"
                ><button class="btn btn-info mb-5">Change Avatar</button></a
              >
              <button v-else class="btn btn-primary">Send Email Invite</button>
              <br />
              <button
                class="btn btn-link p-0 m-0 mt-4"
                v-if="show_delete_team_member != member && member != user.email"
                @click="show_delete_team_member = member"
              >
                X Delete Team Member
              </button>
              <div v-if="show_delete_team_member == member" class="row mt-2">
                <hr />
                <div class="col">
                  <button
                    class="full-width btn btn-secondary"
                    @click="show_delete_team_member = ''"
                  >
                    Cancel
                  </button>
                </div>
                <div class="col">
                  <button
                    class="full-width btn btn-warning"
                    @click="removeTeamMember(member)"
                  >
                    Delete&nbsp;Member?
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col text-light p-3 col-3 text-center">
            <div class="rounded bg-light m-2 border p-3">
              <img
                class="thumbnail rounded mb-1"
                :src="getGravatarUrl('', 100, 'mp')"
              />
              <br /><br />
              <button
                v-if="!show_add_team_member"
                @click="show_add_team_member = true"
                class="btn btn-success mt-3"
              >
                <b>+</b> Add Team Member
              </button>
              <div v-if="show_add_team_member">
                <div class="row">
                  <div class="col">
                    <input
                      class="form-control"
                      v-model="new_team_member"
                      placeholder="Enter email address"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <button
                      class="btn btn-secondary full-width"
                      @click="show_add_team_member = false"
                    >
                      Cancel
                    </button>
                  </div>
                  <div class="col">
                    <button
                      class="btn btn-success full-width"
                      @click="addTeamMember()"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>

              <br /><br /><br />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { initializeApp } from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Google Analytics

// Add Firebase products that you want to use
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";

import { firebaseConfig } from "@/firebase_config.js";
import CryptoJS from "crypto-js";

// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export default {
  name: "TeamPage",
  data() {
    return {
      user: null,
      loaded: false,
      colors: ["primary", "secondary", "success", "warning", "info", "dark"],
      toast: {
        show: false,
        title: "Test title",
        message: "Test Message",
      },
      show_add_team_member: false,
      show_delete_team_member: "",
      new_team_member: "",
      team_members: [],
    };
  },
  methods: {
    login: async function () {
      console.debug("Login !");
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      try {
        const result = await signInWithPopup(auth, provider);
        this.user = result.user;
        console.debug(`User: ${this.user}`);
        console.debug(this.user);
      } catch (e) {
        this.showToast(
          `An error occurred when logging in: ${JSON.stringify(e)}`,
          "Error Logging in"
        );
        console.error(e);
      }
      this.checkIsAdmin();
    },
    logout: async function () {
      const auth = getAuth();
      try {
        await signOut(auth);
        this.user = null;
        console.debug("User logged out");
      } catch (e) {
        this.showToast(
          `An error occurred when logging out: ${JSON.stringify(e)}`,
          "Error Logging out"
        );
        console.error(e);
      }
      this.checkIsAdmin();
    },
    showToast: function (text, title) {
      this.toast.title = title;
      this.toast.message = text;
      this.toast.show = true;
      this.$forceUpdate();
      setTimeout(() => {
        this.toast.show = false;
        this.$forceUpdate();
      }, 5000);
    },
    getEmailHash: function (email) {
      // Normalize the email address
      const normalizedEmail = email.trim().toLowerCase();

      // Hash the email address
      const hash = CryptoJS.MD5(normalizedEmail);

      return hash.toString(CryptoJS.enc.Hex);
    },

    getGravatarUrl: function (email, size = 100, def = "robohash") {
      const hash = this.getEmailHash(email);
      return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=${def}`;
    },
    listTeam: async function () {
      // Get Doc for the List with the user.uid
      var list_doc = await getDoc(doc(db, "list", this.user.uid));
      var retval = list_doc.data();

      if (retval.can_access == undefined) {
        retval.can_access = [this.user.email];
      }

      this.team_members = retval.can_access;
    },
    addTeamMember: async function () {
      var list_doc = await getDoc(doc(db, "list", this.user.uid));
      var retval = list_doc.data();

      // Check if they already exist
      if (retval?.can_access?.indexOf(this.new_team_member) != -1) {
        this.showToast("Error: Team member already exists", "Error");
        return false;
      }

      if (retval?.can_access == undefined) {
        retval.can_access = [];
      }

      retval.can_access.push(this.new_team_member);
      try {
        await setDoc(doc(db, "list", this.user.uid), retval);
      } catch (e) {
        this.showToast(
          "Error: team member not added.  Please try again.",
          "Error"
        );
      }

      // Reset the team member box
      this.new_team_member = "";
      this.show_add_team_member = false;
      this.listTeam();
    },
    removeTeamMember: async function (email) {
      var list_doc = await getDoc(doc(db, "list", this.user.uid));
      var retval = list_doc.data();

      retval.can_access = retval.can_access.filter((x) => x != email);
      try {
        await setDoc(doc(db, "list", this.user.uid), retval);
      } catch (e) {
        this.showToast(
          "Error: team member not deleted.  Please try again.",
          "Error"
        );
      }
      this.listTeam();
    },
    //sendEmailInvite: async function (email) {},
  },
  mounted: async function () {
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(() => {})
      .catch((e) => {
        // TODO: Toast
        console.error(e);
      });

    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        console.debug("USER");
        console.debug(this.user);

        this.listTeam();
      } else {
        console.debug("Not logged in");
      }
    });
    // Turn on all the refs
    Object.values(this.$refs).forEach((ref) => {
      ref.style.display = "";
    });
    this.loaded = true;
    this.$forceUpdate();
  },
};
</script>
