<template>
  <div>
    <div :class="'toast ' + (toast.show ? 'show' : '')" role="alert" aria-live="assertive" style="display: none"
      ref="toast" aria-atomic="true">
      <div class="toast-header text-light bg-primary">
        <strong class="me-auto">{{ toast.title }}</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-light bg-primary">{{ toast.message }}</div>
    </div>
    
    <div class="d-flex justify-content-center p-5" v-if="!loaded">
      <div class="spinner-border" role="status" style="margin-top: 20vh; height: 15vh; width: 15vh"></div>
    </div>

    <main ref="main" style="display: none">
      <section id="hero">
        <h1 class="text fw-bold">
          <i class="bi bi-filter-right"></i>
          FIND DRIFT EVENTS
        </h1>
        <div class="row">
          <div class="col col-4">
            <input class="form-control" placeholder="Search Event Name, Organizer, City, or Zipcode"
              @input="handleSearchChange()" v-model="search" @keydown="handleKeyDown" />

            <div class="autocomplete form-control" v-if="
              Object.keys(search_results).length > 0 && !show_new_event_modal
            " @keydown="handleKeyDown">
              <div class="row" v-for="(item, idx) in Object.keys(search_results)" v-bind:key="'search-results-' + idx">
                <span @click="() => {
                  selected_search_result = idx;
                  selected_search_result_object = search_results[item];
                  search = item;
                  search_results = {};
                }
                  " :class="idx == selected_search_result ? 'bg-primary text-white' : ''
                    ">
                  <i class="bi bi-geo-alt" v-if="search_results[item]?.type == 'location'"></i>
                  <i class="bi bi-person-workspace" v-if="search_results[item]?.type == 'organizer'"></i>
                  <i class="bi bi-calendar3" v-if="search_results[item]?.type == 'event'"></i>
                  &nbsp;{{ item }}
                </span>
              </div>
            </div>
            <div v-if="Object.keys(search_results).length == 0" class="mt-1">
              Search by city, zipcode, or organizer.
            </div>
          </div>

          <div class="col col-2">
            <button type="button" class="btn btn-dark" @click="loadAllEvents()">
              <i class="bi bi-search"></i>&nbsp;&nbsp;Search
            </button>
          </div>
        </div>
      </section>
      <!-- Search Results -->
      <section class="search-results mb-5 featured_events">
        <div class="container bg-dark text-light p-3 rounded mb-3" v-if="featured_events.length > 0">
          <div class="text text-center h4 mb-3 mt-2">Featured Events</div>
          <div v-for="(item, idx) in featured_events" v-bind:key="idx">
            <div class="row rounded p-1 m-1 mb-3" v-if="idx < current_shown">
              <div class="col pt-2">
                <div class="mb-2">
                  Organizer:&nbsp;<b>{{ item?.organizer }}</b>
                </div>
                <!-- Placeholder image-->
                <div class="row text-startpt-3">
                  <div class="col">
                    <h4 class="fw-bold text-uppercase">{{ item.name }}</h4>
                  </div>
                </div>

                <div class="row mt-2 mb-2">
                  <div class="col">
                    <i class="bi bi-map"></i>&nbsp; {{ item.street_address }}
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <i class="bi bi-calendar3"></i>&nbsp;
                    {{ formatDate(item?.start?.date) }}
                    <span v-if="item.start.date != item.end.date">
                      -
                      {{ formatDate(item?.end?.date) }}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col col-6">
                    <button class="btn btn-primary mt-3 w-100" @click="addEventToList(item)" v-if="
                      my_events.events == undefined ||
                      !(item.id in my_events.events)
                    ">
                      <i class="bi bi-calendar-plus"></i>&nbsp;&nbsp; Add to
                      List
                    </button>
                    <button v-else class="btn btn-success mt-3 w-100" @click="removeEventFromList(item)">
                      <i class="bi bi-check-lg"></i>&nbsp; I'm interested in
                      going
                    </button>
                  </div>
                  <div class="col col-3">
                    <button :class="'btn mt-3 w-100 ' +
                      (item.more_info ? 'btn-primary' : 'btn-outline-primary')
                      " @click="item.more_info = !item.more_info">
                      More Info&nbsp;<i :class="'bi ' +
                        (item.more_info ? 'bi-caret-up' : 'bi-caret-down')
                        "></i>
                    </button>
                  </div>
                  <div class="col mt-3" v-if="isAdmin">
                    <button @click="editEvent(item.id)" data-bs-toggle="modal" data-bs-target="#createEvent"
                      class="btn btn-secondary">
                      Edit Event
                    </button>
                  </div>
                </div>
              </div>
              <div class="col col-3">
                <img v-if="item.image && item.image != ''" :src="item.image" alt="Placeholder image"
                  class="rounded mt-2 mb-1" loading="lazy" />
                <img v-else src="images/yeetfleet.jpg" alt="Placeholder image" class="rounded mt-2 mb-1"
                  loading="lazy" />
              </div>

              <div v-if="item.more_info == true">
                <hr class="mt-4" />
                <div class="row bg-light rounded p-3 m-1 mb-3">
                  <div class="col text-dark">
                    <b>Description: </b>{{ item.description }}<br />
                    <b>Nearest City: </b>{{ item.nearest_city }}<br />
                    <b>Venue Name: </b>{{ item.venue_name }}<br />
                    <b>Event Type: </b>{{ item.type }}<br />
                    <b>URL: </b>{{ item.url }}<br />
                    <b>Distance: </b>{{ item.distance }}<br />
                  </div>
                  <div class="col text-end">
                    <iframe width="450" height="250" loading="lazy" frameborder="0" style="border: 1px solid grey"
                      class="rounded border-light" referrerpolicy="no-referrer-when-downgrade" :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDpH8GHZPmjPh-6dUYSr_b2ETzRRy8JYNs&zoom=8&q=' +
                        item.street_address
                        " allowfullscreen>
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container bg-white pt-3 pb-3 rounded mb-3">
          <div class="row">
            <div class="col">
              <div class="text text-center mb-3 h4 fw-normal">
                Upcoming Events Nearby
                <button class="me-1 ms-1 float-end btn btn-link mb-3" data-bs-toggle="modal"
                  data-bs-target="#createEvent" v-if="isAdmin" @click="submitNewEvent()">
                  Submit a New Event!
                </button>
              </div>
            </div>
          </div>
          <div class="row m-1 mb-2 bg-light text-dark p-3 border rounded">
            <div class="col col-2">
              <input class="form-control" v-model="filter_form.radius" />
              <div class="mt-1">Search Radius (mi)</div>
            </div>
            <div class="col col-2">
              <input type="date" class="form-control" v-model="filter_form.start" />
              <div class="mt-1">From</div>
            </div>
            <div class="col col-2">
              <input type="date" class="form-control" v-model="filter_form.end" />
              <div class="mt-1">Until</div>
            </div>
            <div class="col">
              <select class="form-select" aria-label="Select" v-model="filter_form.organizer">
                <option v-for="(item, idx) in organizers" v-bind:key="'organizers-' + idx">{{ item }}</option>
              </select>
              <div class="mt-1">Organizer</div>
            </div>
            <div class="col col-2 text-end">
              <button class="btn btn-success" @click="loadAllEvents()">
                <i class="bi bi-funnel"></i>&nbsp;Find Events
              </button>
            </div>
          </div>
        </div>
        <!-- Confirm Delete Modal -->
        <div class="modal fade" id="deleteEventConfirmationModal" tabindex="-1" aria-labelledby="deleteModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                  Confirm Delete
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                Are you sure you want to delete "{{ new_event.name }}"?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="confirmDeleteButtonCancel">
                  Cancel
                </button>
                <button type="button" class="btn btn-danger" id="confirmDeleteButton" @click="deleteEvent">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Create/Edit New Event Modal -->
        <div class="modal fade" id="createEvent" tabindex="-1" aria-labelledby="createEventLabel" aria-hidden="true">
          <form novalidate class="needs-validation" id="createEventForm" @submit="(e) => {
            e.preventDefault();
            e.stopPropagation();
          }
            ">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h3 class="modal-title" id="createEventLabel">
                    Submit a New Event
                  </h3>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body pe-4 ps-4">
                  <div class="row mt-2 mb-2">
                    <div class="col-4">Organizer</div>
                    <div class="col">
                      <input class="form-control" required v-model.lazy="new_event.organizer" />
                      <div class="invalid-feedback">
                        Please enter an Organizer.
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2 mb-2">
                    <div class="col-4">Event Name</div>
                    <div class="col">
                      <input class="form-control" required v-model.lazy="new_event.name" />
                    </div>
                  </div>

                  <div class="row mt-2 mb-2">
                    <div class="col-4">Featured Event</div>
                    <div class="col">
                      <button :class="'btn ' +
                        (new_event?.featured == true
                          ? 'btn-success'
                          : 'btn-outline-success')
                        " @click="() => {
                          if (new_event?.featured == true) {
                            new_event.featured = false;
                          } else {
                            new_event.featured = true;
                          }
                          $forceUpdate();
                        }
                          ">
                        <i class="bi bi-check-lg" v-if="new_event?.featured == true"></i>
                        Featured Event
                      </button>
                    </div>
                  </div>

                  <div class="row mt-2 mb-2">
                    <div class="col-4">Nearest City</div>
                    <div class="col">
                      <input class="form-control" placeholder="Enter City Name" @input="handleSearchChange()"
                        @keydown="handleKeyDown" required v-model="search" />
                      <div class="autocomplete form-control" required v-if="Object.keys(search_results).length > 0"
                        @keydown="handleKeyDown">
                        <div class="row" v-for="(item, idx) in Object.keys(search_results)"
                          v-bind:key="'search-results-' + idx">
                          <span @click="() => {
                            selected_search_result = idx;
                            selected_search_result_object =
                              search_results[item];
                            new_event.nearest_city = item;
                            search = item;
                            search_results = {};
                            $forceUpdate();
                          }
                            " :class="idx == selected_search_result
                              ? 'bg-primary text-white'
                              : ''
                              ">{{ item }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2 mb-2">
                    <div class="col-4">Street Address</div>
                    <div class="col">
                      <input class="form-control" required v-model.lazy="new_event.street_address" />
                    </div>
                  </div>
                  <div class="row mt-2 mb-2">
                    <div class="col-4">Start Date and Time<br /></div>
                    <div class="col">
                      <input id="startDate" class="form-control" required type="date"
                        v-model.lazy="new_event.start.date" />
                    </div>
                    <div class="col">
                      <input id="startTime" class="form-control" type="time" v-model.lazy="new_event.start.time"
                        required />
                    </div>
                    <div class="col">
                      <select class="form-control" required v-model.lazy="new_event.start.timezone">
                        <option v-for="(item, idx) in Intl.supportedValuesOf(
                          'timeZone'
                        )" v-bind:key="'timezone-1-' + idx" :selected="item == new_event?.start?.timezone">
                          {{ item }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-2 mb-2">
                    <div class="col-4">End Date and Time</div>
                    <div class="col">
                      <input id="startDate" class="form-control" required type="date"
                        v-model.lazy="new_event.end.date" />
                    </div>
                    <div class="col">
                      <input id="startTime" class="form-control" type="time" v-model.lazy="new_event.end.time"
                        required />
                    </div>
                    <div class="col">
                      <select class="form-control" required v-model.lazy="new_event.end.timezone">
                        <option v-for="(item, idx) in Intl.supportedValuesOf(
                          'timeZone'
                        )" v-bind:key="'timezone-' + idx" :selected="item == new_event?.end?.timezone">
                          {{ item }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="row mt-2 mb-2">
                    <div class="col-4">Venue Name</div>
                    <div class="col">
                      <input class="form-control" required v-model.lazy="new_event.venue_name" />
                    </div>
                  </div>
                  <div class="row mt-2 mb-2">
                    <div class="col-4">Type</div>
                    <div class="col">
                      <select class="form-select" required v-model.lazy="new_event.type">
                        <option selected>Amateur Competion</option>
                        <option>Amateur Open</option>
                        <option>Formula Drift Pro</option>
                        <option>Formua Drift Prospec</option>
                        <option>Formula Drift Pro AM</option>
                        <option>Open Drift</option>
                        <option>Invite-Only</option>
                        <option>Exhibition</option>
                      </select>
                    </div>
                  </div>

                  <div class="row mt-2 mb-2">
                    <div class="col-4">URL</div>
                    <div class="col">
                      <input class="form-control" required v-model.lazy="new_event.url" />
                    </div>
                  </div>
                  <div class="row mt-2 mb-2">
                    <div class="col-4">Description</div>
                    <div class="col">
                      <textarea class="form-control" required rows="5" v-model.lazy="new_event.description"></textarea>
                    </div>
                  </div>
                  <div class="row mt-2 mb-1">
                    <div class="col col-4">
                      <label for="formFile" class="form-label">Thumbnail</label>
                    </div>
                    <div class="col input-group mb-3 text-start" v-if="!new_event.upload_running">
                      <div>
                        <img v-if="new_event.preview || new_event.image" :src="new_event.preview || new_event.image"
                          class="img-thumbnail" />

                        <input class="form-control me-2 mt-2" type="file" id="formFile"
                          @change="onEventImageSelected" />
                      </div>
                      <button class="hidden">
                        THIS BUTTON EXISTS SO YOU CAN PRESS ENTER AND NOT UPLOAD
                        RANDOMLY
                      </button>
                      <!--
                      <button
                        class="btn btn-success mt-2"
                        id="upload_event"
                        @click="eventUpload"
                      >
                        Upload
                      </button>
                      -->
                    </div>
                    <div class="spinner-border" role="status" v-else>
                      <div class="sr-only"></div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <div class="me-auto" v-if="new_event.id">
                    <button type="button" class="btn btn-warning" data-bs-toggle="modal"
                      data-bs-target="#deleteEventConfirmationModal">
                      Delete Event
                    </button>
                  </div>

                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="closeEventsButton">
                    Close
                  </button>
                  <button type="submit" class="btn btn-primary" @click="addEditEvent">
                    Submit Event
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- Create New Driver Modal -->
        <div class="modal fade" id="createDriver" tabindex="-1" aria-labelledby="createDriverLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h3>Create/Edit Driver</h3>
              </div>
              <div class="modal-body pe-4 ps-4">
                <div class="row">
                  <div class="col"></div>
                  <div class="col">
                    <h4>Image Uploads</h4>
                    <!-- File Input and Upload Button -->
                    <div>
                      <img v-if="driver_image_upload.preview" :src="driver_image_upload.preview"
                        class="img-thumbnail" />
                      <label for="formFile" class="form-label">Default file input example</label>
                      <input class="form-control" type="file" id="formFile" @change="onDriverImageSelected" />
                    </div>
                  </div>
                  <button class="btn btn-success" id="upload_driver" @click="driverUpload">
                    Upload
                  </button>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="closeCreateDriverButton">
                  Close
                </button>
                <button type="button" class="btn btn-primary" @click="addDriver()">
                  Save Driver
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center p-5" v-if="!results_loaded">
          <div class="spinner-border" role="status" style="height: 5vh; width: 5vh"></div>
        </div>

        <div class="container bg-white rounded pt-3 pb-3" v-else>
          <div v-for="(item, idx) in sorted_data_events" v-bind:key="idx">
            <div class="row border rounded p-1 m-1 mb-3" v-if="idx < current_shown">
              <div class="col pt-2">
                <div class="mb-2">
                  Organizer:&nbsp;<b>{{ item?.organizer }}</b>
                </div>
                <!-- Placeholder image-->
                <div class="row text-startpt-3">
                  <div class="col">
                    <h4 class="fw-bold text-uppercase">{{ item.name }}</h4>
                  </div>
                </div>

                <div class="row mt-2 mb-2">
                  <div class="col">
                    <i class="bi bi-map"></i>&nbsp; {{ item.street_address }}
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <i class="bi bi-calendar3"></i>&nbsp;
                    {{ formatDate(item?.start?.date, item?.start?.timezone) }}
                    <span v-if="item.start.date != item.end.date">
                      -
                      {{ formatDate(item?.end?.date, item?.end?.timezone) }}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col col-6">
                    <button class="btn btn-primary mt-3 w-100" @click="addEventToList(item)" v-if="
                      my_events.events == undefined ||
                      !(item.id in my_events.events)
                    ">
                      <i class="bi bi-calendar-plus"></i>&nbsp;&nbsp; Add to
                      List
                    </button>
                    <button v-else class="btn btn-success mt-3 w-100" @click="removeEventFromList(item)">
                      <i class="bi bi-check-lg"></i>&nbsp; I'm interested in
                      going
                    </button>
                  </div>
                  <div class="col col-3">
                    <button :class="'btn mt-3 w-100 ' +
                      (item.more_info ? 'btn-primary' : 'btn-outline-primary')
                      " @click="item.more_info = !item.more_info">
                      More Info&nbsp;<i :class="'bi ' +
                        (item.more_info ? 'bi-caret-up' : 'bi-caret-down')
                        "></i>
                    </button>
                  </div>
                  <div class="col mt-3" v-if="isAdmin">
                    <button @click="editEvent(item.id)" data-bs-toggle="modal" data-bs-target="#createEvent"
                      class="btn btn-secondary">
                      Edit Event
                    </button>
                  </div>
                </div>
              </div>
              <div class="col col-3">
                <img v-if="item.image && item.image != ''" :src="item.image" alt="Placeholder image"
                  class="rounded mt-2 mb-1" loading="lazy" />
                <img v-else src="images/yeetfleet.jpg" alt="Placeholder image" class="rounded mt-2 mb-1"
                  loading="lazy" />
              </div>

              <div v-if="item.more_info == true">
                <hr class="mt-4" />
                <div class="row bg-light rounded p-3 m-1 mb-3">
                  <div class="col">
                    <b>Description: </b>{{ item.description }}<br />
                    <b>Nearest City: </b>{{ item.nearest_city }}<br />
                    <b>Venue Name: </b>{{ item.venue_name }}<br />
                    <b>Event Type: </b>{{ item.type }}<br />
                    <b>URL: </b>{{ item.url }}<br />
                    <b>Distance: </b>{{ item.distance }}<br />
                  </div>
                  <div class="col text-end">
                    <iframe width="450" height="250" loading="lazy" frameborder="0" style="border: 1px solid grey"
                      class="rounded border-light" referrerpolicy="no-referrer-when-downgrade" :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDpH8GHZPmjPh-6dUYSr_b2ETzRRy8JYNs&zoom=8&q=' +
                        item.street_address
                        " allowfullscreen>
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="current_shown < sorted_data_events.length" class="text-center mt-4 mb-4">
            <button class="btn btn-primary" @click="current_shown += 10">
              <i class="bi-caret-down-fill"></i>&nbsp; Show More Events ({{
                sorted_data_events.length - current_shown
              }}
              More)
            </button>
          </div>
        </div>
      </section>

      <!-- Socials -->
      <section id="socials">
        <div class="container">
          <h2 class="text-start mb-4 text-dark">SOCIALS</h2>
          <div class="bg-white rounded p-4 m-4">
            Check out our instagram at
            <a href="https://www.instagram.com/driftlist/">@driftlist</a>.
            <br /><br />
            Please send us any problems, complaints, criticisms, feedback,
            attaboys, threats of minor bodily harm, insider trading tips, or
            general correspondences at
            <a href="mailto:staff@driftlist.com">staff@driftlist.com</a>.
            &nbsp;&nbsp;Actually, please use Instagram, so we get
            traffic.&nbsp;&nbsp;kthx.
          </div>

          <div class="row mt-2 ml-auto mr-auto">
            <div v-for="(item, idx) in instagram_feed?.posts" v-bind:key="'instagram-feed-' + idx"
              class="col m-1 mb-3 text-center" style="min-width: 24%; max-width: 24%">
              <a :href="item?.mediaUrl">
                <img :src="item?.mediaUrl" alt="Instagram image" class="rounded" width="350vh" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>

    <footer ref="footer" style="display: none">
      Thanks to
      <a href="https://simplemaps.com/data/us-zips" nofollow>Simple Maps</a> for
      use of their geolocation dataset!
    </footer>

  </div>
</template>
<script>
import { initializeApp } from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Google Analytics
//import { getAnalytics } from "firebase/analytics";

// Add Firebase products that you want to use
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  query,
  deleteDoc,
  limit,
  where,
  updateDoc,
  GeoPoint,
} from "firebase/firestore";

import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

import { firebaseConfig } from "@/firebase_config.js";

import moment from 'moment';
import 'moment-timezone';

// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
export const db = getFirestore(app);
const storage = getStorage(app);

export async function getEvent(db, eventId) {
  // Create a reference to the specific document in the 'events' collection
  const eventDocRef = doc(db, "events", eventId);

  // Fetch the document from Firestore

  const docSnap = await getDoc(eventDocRef);

  // Check if the document exists
  if (docSnap.exists()) {
    // If it exists, return the data of the document
    return docSnap.data();
  } /* c8 ignore next */ else {
    return null;
  }
}

export function toDateTime(dateStr, timeStr, timezone) {
  // Combine date and time into a single string
  const dateTimeStr = `${dateStr}T${timeStr}`;

  // Create a date object (in local timezone)
  let dateTime = new Date(dateTimeStr);

  // Fetch the timezone offset for 'America/Chicago' at the given date
  // Note: This requires a library like 'moment-timezone' since native JS does not handle timezones
  const timezoneOffset = moment.tz(dateTimeStr, timezone).utcOffset();

  // Convert the local date object to UTC, then apply the 'America/Chicago' offset
  dateTime = new Date(dateTime.getTime() + timezoneOffset * 60000);

  return dateTime;
}

export function generateUUID() {
  // Check if the native method is available
  if (window.crypto && window.crypto.randomUUID) {
    return window.crypto.randomUUID();
  }

  // Fallback UUID generation
  let d = new Date().getTime(); //Timestamp
  let d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

// Validation
export function validateNewEvent() {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var form = document.querySelector("#createEventForm");

  // Loop over them and prevent submission
  var retval = true;

  if (!form.checkValidity()) {
    retval = false;
  }

  form.classList.add("was-validated");

  return retval;
}
export function resetEventForm() {
  document.getElementById("createEventForm").classList.remove("was-validated");
}
export function isNumeric(str) {
  return Array.from(str).every((c) => c >= "0" && c <= "9");
}

// Geolocation
// Constants
//const EARTH_RADIUS_MILES = 3959;
const MILES_PER_DEGREE_LATITUDE = 69;

// Function to calculate degree change for latitude and longitude
function calculateDegreeChange(miles, latitude) {
  const deltaLat = miles / MILES_PER_DEGREE_LATITUDE;
  const deltaLng =
    miles / (Math.cos(latitude * (Math.PI / 180)) * MILES_PER_DEGREE_LATITUDE);
  return { deltaLat, deltaLng };
}

// Function to calculate the distance between two points
function calculateDistance(lat1, lng1, lat2, lng2) {
  const radLat1 = (Math.PI * lat1) / 180;
  const radLat2 = (Math.PI * lat2) / 180;
  const theta = lng1 - lng2;
  const radTheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radLat1) * Math.sin(radLat2) +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515; // Miles
  return dist;
}
export default {
  data() {
    return {
      loaded: false,
      results_loaded: false,
      user: null,
      isAdmin: false,
      data_events: [],
      featured_events: [],
      search: "",
      search_results: {},
      show_new_event_modal: false,
      selected_search_result: null,
      selected_search_result_object: null,
      default_timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
      new_event: {
        name: "",
        nearest_city: "",
        start: {
          date: "",
          time: "",
          timezone: this.default_timezone,
        },
        end: {
          date: "",
          time: "",
          timezone: this.default_timezone,
        },
        street_address: "",
        url: "",
        description: "",
        image: null,
      },
      my_events: {},

      driver_image_upload: {
        preview: null,
      },

      sample_driver: {
        name: "Max Verstappen",
        car: {
          link: "/?car=susie",
          text: '"Susie" (Honda Civic 1999)',
        },
        pro: "Amateur",
        sponsors: "Nike, Rogane",
        "number of events attended": 542,
        "next event": {
          text: "Super Secret Drift Event",
          link: "/?event=super_secret",
        },
        "number of golden tires": 300,
      },
      sample_driver_image: "images/sample-drivers/driver_1.jpg",
      sample_car: {
        name: "Susie",
        make: "Honda",
        model: "Civic",
        year: 1999,
        owner: {
          text: "Max Verstappen",
          link: "/?driver=max",
        },
        engine: "V100 V-Tech Max",
        transmission: "Major Tom",
        body: "Gyat",
        hood: "R.A.T. Carbon Fiber",
        helmet: "Much Safe",
        "for sale": "yes",
        price: 10000000,
      },
      sample_car_image: "images/sample-cars/yeetfleet.jpg",

      instagram_feed: {},
      toast: {
        show: false,
        title: "Test title",
        message: "Test Message",
      },

      filter_form: {
        radius: 500,
        start: new Date().toLocaleDateString("en-CA"),
        end: new Date(
          new Date().getFullYear() + 1,
          new Date().getMonth(),
          new Date().getDate()
        )
          .toISOString()
          .split("T")[0],
        organizer: "All Organizers",
      },

      organizers: [],
      current_shown: 10,
      months: {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      },
    };
  },
  watch: {
    search: function () {
      console.debug("SELECTED SEARCH RESULT");
      console.debug("Automatic Timezone update");

      if (this.selected_search_result_object == null) {
        this.selected_search_result_object = Object.values(this.search_results)[
          this.selected_search_result
        ];
      }

      if (
        this.selected_search_result_object?.timezone != undefined &&
        this.selected_search_result_object?.timezone.indexOf("/") != -1
      ) {
        this.new_event.start.timezone =
          this.selected_search_result_object.timezone;
        this.new_event.end.timezone =
          this.selected_search_result_object.timezone;
      } else if (
        this.selected_search_result_object?.undefined != undefined &&
        this.selected_search_result_object?.undefined.indexOf("/") != -1
      ) {
        // This is a quirk of the dataset
        this.new_event.start.timezone =
          this.selected_search_result_object.undefined;
        this.new_event.end.timezone =
          this.selected_search_result_object.undefined;
      }

      this.$forceUpdate();
    },
  },
  methods: {
    showToast: function (text, title) {
      this.toast.title = title;
      this.toast.message = text;
      this.toast.show = true;
      this.$forceUpdate();
      setTimeout(() => {
        this.toast.show = false;
        this.$forceUpdate();
      }, 5000);
    },

    checkIsAdmin: async function () {
      //const auth = getAuth();
      getAuth()
      try {
        const eventsCol = collection(db, "admins");
        //const allDocs = await getDocs(eventsCol);
        await getDocs(eventsCol);
        console.debug("Admin");
        this.isAdmin = true;
      } catch (e) {
        console.error(e);
        this.isAdmin = false;
      }
    },
    
    handleSearchChange: async function () {
      console.debug("Handle Search Change");
      this.search_results = {};

      var search_term_splits = this.search.split(",");
      var search_term = search_term_splits[0];
      // First letter capitalization
      search_term = search_term.charAt(0).toUpperCase() + search_term.slice(1);

      const endText = search_term + "\uf8ff";

      if (search_term.length > 2 && isNumeric(search_term)) {
        console.debug("We have a Zipcode");
        var q = query(
          collection(db, "zipcodes-locations"),
          where("zip", ">=", search_term),
          where("zip", "<=", endText)
        );
        var queryResults = await getDocs(q);
        queryResults.forEach((x) => {
          var y = x.data();
          y.type = "location";
          var temp = `${y.city}, ${y.state_id} (${y.zip})`;
          this.search_results[temp] = y;
        });
      } else if (search_term.length > 2) {
        console.debug("In search length");
        // const auth = getAuth(); //NOTE: We might have to remove this for performance

        q = query(
          collection(db, "zipcodes-locations"),
          where("city", ">=", search_term),
          where("city", "<=", endText)
        );

        var q2 = query(
          collection(db, "events"),
          where("organizer", ">=", search_term),
          where("organizer", "<=", endText)
        );

        var q3 = query(
          collection(db, "events"),
          where("name", ">=", search_term),
          where("name", "<=", endText)
        );

        const [queryResults, queryResultsOrganizers, queryResultsEvents] =
          await Promise.all([getDocs(q), getDocs(q2), getDocs(q3)]);

        queryResultsEvents.forEach((x) => {
          var y = x.data();
          y.type = "event";
          var temp = `${y.name}`;
          this.search_results[temp] = y;
        });

        queryResultsOrganizers.forEach((x) => {
          var y = x.data();
          y.type = "organizer";
          var temp = `${y.organizer}`;
          this.search_results[temp] = y;
        });
        queryResults.forEach((x) => {
          var y = x.data();
          y.type = "location";
          var temp = `${y.city}, ${y.state_id} (${y.zip})`;

          // Check if we have a state
          if (
            search_term_splits.length > 1 &&
            search_term_splits[1].replace(/\s/g, "") != "" &&
            search_term_splits[1].length >= 2
          ) {
            if (
              y.state_id.replace(/\s/g, "").toLowerCase() ===
              search_term_splits[1].replace(/\s/g, "").toLowerCase()
            ) {
              this.search_results[temp] = y;
            }
          } else {
            this.search_results[temp] = y;
          }
        });
      }
      console.debug("Done");
      console.debug(this.search_results);
    },

    handleKeyDown: function (event) {
      if (event.key == "ArrowUp") {
        if (
          this.selected_search_result == null ||
          this.selected_search_result == 0
        ) {
          this.selected_search_result = 0;
        } else {
          this.selected_search_result -= 1;
        }
      } else if (event.key == "ArrowDown") {
        if (this.selected_search_result == null) {
          this.selected_search_result = 0;
        } else if (
          Object.keys(this.search_results).length - 1 <=
          this.selected_search_result
        ) {
          this.selected_search_result = 0;
        } else {
          this.selected_search_result += 1;
        }
      } else if (event.key == "Enter") {
        this.selected_search_result_object = Object.values(this.search_results)[
          this.selected_search_result
        ];
        this.search = `${this.selected_search_result_object.city}, ${this.selected_search_result_object.state_id}`;
        this.search_results = {};
      }
    },
    loadInstagramFeed: async function () {
      var url = "https://feeds.behold.so/t9GBfMbczmfifiYao6cF";
      var response = await fetch(url);
      var data = await response.json();
      this.instagram_feed = data;

    },
    submitNewEvent: function () {
      Object.keys(this.new_event).forEach((x) => {
        if (x != "end" && x != "start") {
          this.new_event[x] = "";
        }
      });
      Object.keys(this.new_event["start"]).forEach((x) => {
        if (x != "timezone") {
          this.new_event["start"][x] = "";
        } else {
          this.new_event["start"][x] = this.default_timezone;
        }
      });
      Object.keys(this.new_event["end"]).forEach((x) => {
        if (x != "timezone") {
          this.new_event["end"][x] = "";
        } else {
          this.new_event["end"][x] = this.default_timezone;
        }
      });

      // Update default times

      this.new_event["start"]["time"] = "00:00";
      this.new_event["end"]["time"] = "00:00";

      this.search = "";
      this.resetEventForm();
      this.show_new_event_modal = true;
    },
    addEditEvent: async function (e) {
      e.preventDefault();
      if (!validateNewEvent()) {
        return 0;
      }
      // Remove preview image
      delete this.new_event.preview;

      var selected_search_result_object = this.selected_search_result_object;

      console.log("SELECTED SEARCH RESULT OBJECT");
      console.log(selected_search_result_object);

      // Find the nearest city geolocation coordinates
      let newGeoPoint;
      if (
        selected_search_result_object != undefined &&
        selected_search_result_object.lat != undefined &&
        selected_search_result_object.lng != undefined
      ) {
        newGeoPoint = new GeoPoint(
          selected_search_result_object.lat,
          selected_search_result_object.lng
        );
      } else if (
        selected_search_result_object != undefined &&
        selected_search_result_object.location != undefined
      ) {
        newGeoPoint = selected_search_result_object.location;
      } else {
        newGeoPoint = this.new_event.location;
      }
      if (
        this.selected_search_result_object != undefined &&
        this.selected_search_result_object.city != undefined &&
        this.selected_search_result_object.state_id != undefined
      ) {
        this.new_event.nearest_city = `${this.selected_search_result_object.city}, ${this.selected_search_result_object.state_id}`;
      }

      // Convert the start and ends to proper datetimes (with timezone)
      console.debug(this.new_event.start.time);
      var start = toDateTime(
        this.new_event.start.date,
        this.new_event.start.time,
        this.new_event.start.timezone
      );

      var end = toDateTime(
        this.new_event.end.date,
        this.new_event.end.time,
        this.new_event.end.timezone
      );

      // Adds an event
      var temp = JSON.parse(JSON.stringify(this.new_event));
      //delete temp.start;
      //delete temp.end;

      temp.start_date = start;
      temp.end_date = end;
      temp.location = newGeoPoint;

      if (this.new_event.id) {
        console.debug("Updating event...");
        const eventDocRef = doc(db, "events", this.new_event.id);
        delete this.new_event.id;
        await updateDoc(eventDocRef, this.new_event);
        this.toast.title = "Updated event";
        this.toast.message = "Event updated successfully.";
      } else {
        console.debug("New Event");
        const col = collection(db, "events");
        //var id = 
        await addDoc(col, temp);
        this.toast.title = "Created New Event";
        this.toast.message = "Event created successfully.";
      }
      await this.loadAllEvents();
      // Simulate clicking the close button
      document.getElementById("closeEventsButton").click();
      this.toast.show = true;
      setTimeout(() => (this.toast.show = false), 3000);

      return this.new_event;
    },
    deleteEvent: async function () {
      if (this.new_event.id) {
        const retval = deleteDoc(doc(db, "events", this.new_event.id));
        await this.loadAllEvents();
        document.getElementById("confirmDeleteButtonCancel").click();
        this.toast.title = "Deleted Event";
        this.toast.message = "Event deleted successfully.";
        this.toast.show = true;
        setTimeout(() => (this.toast.show = false), 3000);

        return retval;
      }
    },

    listAllMyEvents: async function () {
      // List all my events from my list, if it exists
      try {
        var list_doc = await getDoc(doc(db, "list", this.user.uid));
        var retval = list_doc.data();
        this.my_events = retval;
      } catch (e) {
        console.log("[listAllMyEvents] Document does not exist");
      }
    },
    formatDate: function (date, timezone) {
      try {
        var a = new Date(date);
        if (timezone != undefined) {
          a.timezone = timezone;
        }

        const str = a.toISOString().split("T")[0].split("-");
        return `${this.months[str[1]]} ${str[2]}, ${str[0]}`;
        //return a.toISOString().split("T")[0];
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    addEventToList: async function (event) {
      var list_doc = doc(db, "list", this.user.uid);
      if (this.user.uid == undefined) {
        this.showToast(
          `Cannot add event to list when logged out.`,
          "Please login"
        );
        console.error("Cannot add event to list when not logged in");
        return false;
      }
      let data;

      try {
        list_doc = await getDoc(list_doc);
        data = list_doc.data();
      } catch (e) {
        data = {
          owner_email: this.user.email,
          can_access: [this.user.email],
          events: {},
          blocked_times: [],
        };
      }
      // Add Event to list
      data.events[event.id] = event.name;

      await setDoc(doc(db, "list", this.user.uid), data);
      this.listAllMyEvents();
    },
    removeEventFromList: async function (event) {
      var list_doc = doc(db, "list", this.user.uid);
      if (this.user.uid == undefined) {
        this.showToast(
          `Cannot remove an event from the list when logged out.`,
          "Please login"
        );
        console.error("Cannot add event to list when not logged in");
        return false;
      }
      let data;

      try {
        list_doc = await getDoc(list_doc);
        data = list_doc.data();
      } catch (e) {
        return false; // If it doesn't exist, we can't remove it
      }
      // Add Event to list
      delete data.events[event.id];

      await setDoc(doc(db, "list", this.user.uid), data);
      this.listAllMyEvents();
    },

    onEventImageSelected: async function (event) {
      console.debug("EVENT IMAGE SELECTED");
      console.debug(event);
      var file = event.target.files[0];
      this.new_event.image = file;
      this.new_event.preview = URL.createObjectURL(file);
      // NOTE: I had these as two functions, but I'm combining for usability
      /* }, */
      /* eventUpload: async function (e) { */
      console.debug("EVENT UPLOAD");
      console.debug(event);
      this.new_event.upload_running = true;
      const storageReference = storageRef(
        storage,
        `events/${generateUUID()}.jpg`
      );
      try {
        const snapshot = await uploadBytes(
          storageReference,
          this.new_event.image
        );
        const downloadURL = await getDownloadURL(snapshot.ref);
        this.new_event.image = downloadURL;
        delete this.new_event.preview;
        delete this.new_event.upload_running;
      } catch (e) {
        this.showToast(
          `An error occurred when selecting an image: ${JSON.stringify(e)}`,
          "Error Selecting Image"
        );
        console.error(e);
      }
    },
    editEvent: async function (id) {
      resetEventForm();
      var output = await getEvent(db, id);

      await this.handleSearchChange();
      // TODO: THIS MIGHT BE WRONG
      var found = Object.keys(this.search_results).filter(
        () => output.nearest_city
      );
      this.selected_search_result_object = this.search_results[found[0]];
      this.search = output.nearest_city;
      this.search_results = {};

      this.new_event = output;
      this.new_event.id = id;
      this.show_new_event_modal = true;
      await this.$forceUpdate();
    },
    resetEventForm: resetEventForm,

    listOrganizers: async function () {
      var col = collection(db, "events");
      var all_items = await getDocs(col);
      var retval = new Set();
      all_items.forEach((x) => {
        var organizer = x.data().organizer;
        if (organizer) {
          retval.add(organizer);
        }
      });
      this.organizers = ["All Organizers", ...Array.from(retval)];
    },

    listAllFeaturedEvents: async function () {
      // List all featured events
      var q = query(collection(db, "events"), where("featured", "==", true));

      var queryResults = await getDocs(q);
      var retval = [];
      queryResults.forEach((x) => {
        var y = x.data();
        // TODO: Probably want to check if the event is already past or not
        console.debug("FEATURED EVENT:");
        console.debug(y);
        retval.push({ id: x.id, ...y });
      });
      this.featured_events = retval;
    },

    // Driver uploads
    onDriverImageSelected: function (event) {
      console.debug(event);
      var file = event.target.files[0];
      this.driver_image_upload.file = file;
      this.driver_image_upload.preview = URL.createObjectURL(file);
      return this;
    },
    driverUpload: async function () {
      const storageReference = storageRef(storage, "drivers/test.jpg");
      try {
        const snapshot = await uploadBytes(
          storageReference,
          this.driver_image_upload.file
        );
        const downloadURL = await getDownloadURL(snapshot.ref);
        console.debug("Download URL");
        console.debug(downloadURL);
      } catch (e) {
        this.showToast(
          `An error occurred uploading driver image: ${JSON.stringify(e)}`,
          "Error Uploading Image"
        );
        console.error(e);
      }
    },
    loadAllEvents: async function (maximum_events) {
      this.results_loaded = false;
      // Default function to load all events
      console.debug("Loading all events...");
      const eventsCol = collection(db, "events");
      let allDocs;

      // Handle any searches or filters
      if (this.selected_search_result_object?.type == "location") {
        allDocs = await getDocs(eventsCol);

        console.debug("Searching by location...");
        var latitude = this.selected_search_result_object.lat;
        var longitude = this.selected_search_result_object.lng;
        const miles = this.filter_form.radius; // Defaults to 500

        const { deltaLat, deltaLng } = calculateDegreeChange(miles, latitude);

        const minLat = latitude - deltaLat;
        const maxLat = latitude + deltaLat;
        const minLng = longitude - deltaLng;
        const maxLng = longitude + deltaLng;

        console.debug(`Max: ${maxLat}, ${maxLng}`);
        console.debug(`Min: ${minLat}, ${minLng}`);

        var retval = [];
        allDocs.forEach((doc) => {
          // See if it's nearby
          var data = doc.data();
          console.debug(data.location);
          console.debug(data.nearest_city);
          var distance = calculateDistance(
            latitude,
            longitude,
            data.location.latitude,
            data.location.longitude
          );
          if (distance <= miles) {
            retval.push({ ...data, id: doc.id, distance: distance });
          }
        });
        this.data_events = retval;
      } else if (this.selected_search_result_object?.type == "organizer") {
        console.debug(this.selected_search_result_object?.organizer);
        var q = query(
          collection(db, "events"),
          where(
            "organizer",
            "==",
            this.selected_search_result_object?.organizer
          )
        );

        var queryResults = await getDocs(q);
        retval = [];
        queryResults.forEach((x) => {
          var y = x.data();
          retval.push({ id: x.id, ...y });
        });
        this.data_events = retval;
      } else if (this.selected_search_result_object?.type == "event") {
        q = query(
          collection(db, "events"),
          where("name", "==", this.selected_search_result_object?.name)
        );

        queryResults = await getDocs(q);
        retval = [];
        queryResults.forEach((x) => {
          var y = x.data();
          retval.push({ id: x.id, ...y });
        });
        this.data_events = retval;
      } else {
        // This is not a search - this has filters
        if (maximum_events == undefined) {
          allDocs = await getDocs(eventsCol);
        } else {
          allDocs = await getDocs(query(eventsCol, limit(maximum_events)));
        }
        retval = [];
        allDocs.forEach((doc) => {
          retval.push({ id: doc.id, ...doc.data() });
        });
        console.debug(retval);
        this.data_events = retval;
      }

      this.filterEvents();
      this.results_loaded = true;

      return this.data_events;
    },
    filterEvents: function () {
      console.debug("Filter Events...");
      this.data_events = this.data_events.filter((event) => {
        var event_start = event?.start?.date;
        var event_end = event?.end?.date;

        var filter_start = this.filter_form?.start;
        var filter_end = this.filter_form?.end;

        var event_check = false;

        if (event_start && event_end && filter_start && filter_end) {
          event_check =
            new Date(event_start) <= new Date(filter_end) &&
            new Date(event_end) >= new Date(filter_start);
        }

        // Organizer check
        var organizer_check = false;
        if (
          this.filter_form?.organizer == null ||
          this.filter_form?.organizer === "All Organizers"
        ) {
          console.debug("All Organizers set");
          organizer_check = true;
        } else {
          console.debug("Checking organizer...");
          organizer_check = this.filter_form?.organizer == event?.organizer;
        }
        console.debug(`Organizer check:${organizer_check}`);
        console.debug(`Event check:${event_check}`);
        if (organizer_check && event_check) {
          console.debug(event);
        }
        return organizer_check && event_check;
      });
    },
    findIP: async function () {
      var response = await fetch(
        "https://api.ipgeolocation.io/ipgeo?apiKey=21feff27abcc4783bb7885ff954dfaef"
      );
      // Check if the response is successful
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
  },
  computed: {
    sorted_data_events: function () {
      if (!this.data_events) {
        return [];
      }
      var items = this.data_events.slice();
      return items.sort(
        (a, b) => new Date(a.start.date) - new Date(b.start.date)
      );
    },
  },
  mounted: async function () {
    const auth = getAuth();

    // Force use of local persistence - probably unnecessary
    setPersistence(auth, browserLocalPersistence)
      .then(() => { })
      .catch((e) => {
        this.showToast(
          `An error occurred logging in (persistance): ${JSON.stringify(e)}`,
          "Error Logging in"
        );
        console.error(e);
      });

    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        console.debug("USER");
        console.debug(this.user);

        this.listAllMyEvents();
      } else {
        console.debug("Not logged in");
      }
    });
    // Get all Events

    try {
      var current_ip = await this.findIP();
      this.selected_search_result_object = {
        type: "location",
        lat: current_ip.latitude,
        lng: current_ip.longitude,
      };
      this.search = `${current_ip.city}, ${current_ip.state_code.replace(
        "US-",
        ""
      )}`;
    } catch (e) {
      /*
      this.showToast(
        `An error occurred with determining Location: ${JSON.stringify(e)}`,
        "Error Determining Location"
      );*/
      console.error(JSON.stringify(e))
      console.error("Failed to load current IP");
    }
    this.loadAllEvents();
    this.listAllFeaturedEvents();
    this.listOrganizers();
    // Checks if Admin
    this.checkIsAdmin();
    this.loadInstagramFeed();

    // Handle the modal when it exits
    const modal = document.getElementById("createEvent");
    console.debug(modal);
    modal.addEventListener("hidden.bs.modal", () => {
      this.show_new_event_modal = false;
    });

    // Turn on all the refs
    Object.values(this.$refs).forEach((ref) => {
      ref.style.display = "";
    });
    this.loaded = true;
    this.$forceUpdate();
  },
}
</script>