// src/router.js

import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/HomePage.vue';
import Team from './views/TeamPage.vue';
import Events from './views/EventsPage.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/team',
    name: 'Team',
    component: Team
  },
  {
    path: '/events',
    name: 'Events',
    component: Events 

  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
