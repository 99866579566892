const firebaseConfig = {
  "apiKey": "AIzaSyAg2hJWmrM8vk3IyQBVAH288Kp72bTIwuI",
  "authDomain": "driftlist-968f7.firebaseapp.com",
  "projectId": "driftlist-968f7",
  "storageBucket": "driftlist-968f7.appspot.com",
  "messagingSenderId": "921785170789",
  "appId": "1:921785170789:web:cea98dbff61157e03a3f8c",
  "measurementId": "G-T8Y34ZKP18"
}


export { firebaseConfig }